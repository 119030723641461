import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import styles from './ChangePassword.module.scss';
import { config } from '@config';
import {
  Col,
  Row,
  Typography,
  Input,
  Link,
  Button,
  offsets,
  display,
  openNotification,
  flex,
  common,
  openStatusNotification
} from '@xq/ui-kit';
import {
  ChangePasswordService,
  ChangePasswordServiceApi
} from './change-password-service';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { submitForm } from '@services';
import { getStatusNotificationTranslations } from '@services/notifications';
import { PasswordRestriction } from '@pages/ChangePassword/dataTypes';
import {handleTranslationErrorFromStatus} from "@services/translationErrorFromStatus";

export const ChangePassword: FC = () => {
  const service: ChangePasswordService = new ChangePasswordServiceApi();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const defaultPasswordRestrictions: PasswordRestriction[] = [
    {
      title: t('changePassword.charactersMinimumRestriction'),
      regex: /^.{9,}$/,
      isValid: false
    },
    {
      title: t('changePassword.charactersMaximumRestriction'),
      regex: /^(.{0,60})$/,
      isValid: false
    },
    {
      title: t('changePassword.lowercaseLetterRestriction'),
      regex: /[a-z]/,
      isValid: false
    },
    {
      title: t('changePassword.uppercaseLetterRestriction'),
      regex: /[A-Z]/,
      isValid: false
    },
    {
      title: t('changePassword.numberRestriction'),
      regex: /\d/,
      isValid: false
    },
    {
      title: t('changePassword.specialCharacterRestriction'),
      regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
      isValid: false
    }
  ];

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [passwordRestrictions, setPasswordRestrictions] = useState<
    PasswordRestriction[]
  >(defaultPasswordRestrictions);

  const isFieldEmpty = (field: string): boolean => {
    return field.length === 0;
  };

  const validatePassword = (): boolean => {
    return newPassword === confirmNewPassword;
  };

  const testRegex = (regex: RegExp, value: string): boolean => {
    return value && regex.test(value);
  };

  const isRestrictionsValid: boolean = useMemo(() => {
    return passwordRestrictions?.reduce((result, restriction) => {
      return result && restriction.isValid;
    }, true);
  }, [passwordRestrictions]);

  useEffect(() => {
    const isNotValid: boolean =
      isFieldEmpty(oldPassword) ||
      isFieldEmpty(newPassword) ||
      isFieldEmpty(confirmNewPassword) ||
      !isRestrictionsValid ||
      newPassword !== confirmNewPassword;
    setDisabled(isNotValid);
  }, [oldPassword, newPassword, confirmNewPassword, isRestrictionsValid]);

  useEffect(() => {
    const updatedRestrictions: PasswordRestriction[] = passwordRestrictions.map(
      (restriction) => {
        const isValid = testRegex(restriction.regex, newPassword);
        return { ...restriction, isValid };
      }
    );
    setPasswordRestrictions(updatedRestrictions);
  }, [newPassword]);

  async function save() {
    if (!validatePassword()) {
      openNotification(
        t('changePassword.passwordsDontMatch'),
        'error',
        config.notificationDelay
      );
      return;
    }

    setIsLoading(true);

    try {
      await service.changePassword({ oldPassword, newPassword });
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('changePassword.passwordChangedSuccessfully')
      });

      navigate('/');
    } catch (error) {
      const localizedErrorMessage = handleTranslationErrorFromStatus(t, error);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: localizedErrorMessage
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const cancel = () => {
    navigate('/');
  };

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Typography className={offsets['mb-40']} variant="h2">
            {t('common.changePassword')}
          </Typography>
        </Col>
        <Col col={10} sm={6} md={3}>
          <form onSubmit={submitForm}>
            <Input
              className={cn(offsets['mb-20'], display['d-block'])}
              value={oldPassword}
              type="password"
              disabled={isLoading}
              onChange={(value) => setOldPassword(String(value))}
              label={t('changePassword.oldPassword')}
              withIcon={true}
            />
            <Input
              className={cn(offsets['mb-20'], display['d-block'])}
              value={newPassword}
              type="password"
              disabled={isLoading}
              onChange={(value) => setNewPassword(String(value))}
              label={t('changePassword.newPassword')}
              withIcon={true}
            />
            <Input
              className={cn(offsets['mb-20'], display['d-block'])}
              value={confirmNewPassword}
              type="password"
              disabled={isLoading}
              onChange={(value) => setConfirmNewPassword(String(value))}
              label={t('changePassword.confirmNewPassword')}
              withIcon={true}
            />

            <ul className={styles['password-list']}>
              {passwordRestrictions.map((restriction, idx) => {
                return (
                  <li
                    key={idx}
                    className={
                      restriction.isValid
                        ? styles['password-item--valid']
                        : styles['password-item']
                    }
                  >
                    <Typography
                      variant={'body-4'}
                      element={'div'}
                      className={offsets['mb-10']}
                    >
                      {restriction.title}
                    </Typography>
                  </li>
                );
              })}
            </ul>

            <Typography variant={'body-1'} element={'div'}>
              {t('changePassword.ifForgotPassword')} -
            </Typography>
            <Link
              href={`${config.setResetPasswordUrl}`}
              type={'primary'}
              className={cn(
                display['d-block'],
                offsets['mb-40'],
                common.lowercase
              )}
            >
              {t('changePassword.useResetForm')}
            </Link>
            <div className={cn(display['d-flex'], flex['align-items-center'])}>
              <Button
                buttonType={'submit'}
                onClick={save}
                isLoading={isLoading}
                disabled={disabled}
                className={offsets['mr-20']}
              >
                {t('common.save')}
              </Button>
              <Button type="secondary" onClick={cancel} isLoading={isLoading}>
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Fragment>
  );
};

ChangePassword.displayName = 'ChangePassword';
