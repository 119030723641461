import { wait, handleBackendError, iamGateway } from '@services';
import { AuthApiAuthControllerChangePasswordRequest } from '@xq/iam-frontend-client';

export interface ChangePasswordServiceForm {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordService {
  changePassword(model: ChangePasswordServiceForm): Promise<void>;
}

export class ChangePasswordServiceApi implements ChangePasswordService {
  async changePassword(model: ChangePasswordServiceForm): Promise<void> {
    try {
      const requestParameters: AuthApiAuthControllerChangePasswordRequest = {
        authDTORequestChangePassword: {
          newPassword: model.newPassword,
          currentPassword: model.oldPassword
        }
      };
      await iamGateway.authControllerChangePassword(requestParameters);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.changePassword.name
      );
    }
  }
}

export class ChangePasswordServiceMock implements ChangePasswordService {
  async changePassword(model: ChangePasswordServiceForm): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.changePassword.name
      );
    }
  }
}
