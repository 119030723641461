import React from 'react';

export interface UserContextData {
  firstName: string;
  lastName: string;
  avatar: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setAvatar: (value: string) => void;
}

const userContextData: UserContextData = {
  firstName: '',
  lastName: '',
  avatar: '',
  setFirstName: () => {},
  setLastName: () => {},
  setAvatar: () => {}
};

export const UserContext =
  React.createContext<UserContextData>(userContextData);
