import * as React from 'react';
import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  NotFound,
  InternalServerError,
  Settings,
  ChangePassword,
  Forbidden
} from '@pages';
import { Layout } from './layouts/Layout';

export const App: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Settings />} />
        <Route path="change-password" element={<ChangePassword />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="internal-error" element={<InternalServerError />} />
      <Route path="forbidden" element={<Forbidden />} />
    </Routes>
  );
};
