import { FormEvent } from 'react';
import { LicenseProductDTOResponse } from '@xq/user-profile-gateway-frontend-client';
import { config } from '@config';
import { generateHeaderAppSwitcherItems, HeaderAppItem, HeaderAppNames, HeaderAppSwitcherUrls } from '@xq/ui-kit';
import { appNameMapperByUUID } from '@services';

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));


export const generateHeaderAppItems = (
  isOrganizationAdmin?: boolean,
  isXQAdmin?: boolean
): HeaderAppItem[] => {
  const accountApp: HeaderAppItem = {
    name: HeaderAppNames.Account,
    url: '/'
  };

  const headerAppUrls: HeaderAppSwitcherUrls = {
    modelTreeFrontendUrl: config.modelTreeFrontendUrl,
    insightsFrontendUrl: config.insightsFrontendUrl,
    administrationFrontendUrl: config.administrationFrontendUrl,
    omniFrontendUrl: config.omniFrontendUrl
  };

  return [accountApp, ...generateHeaderAppSwitcherItems(
    headerAppUrls,
    isOrganizationAdmin,
    isXQAdmin
  )]

};

export const submitForm = (e: FormEvent) => {
  e.preventDefault();
};

export const redirectToSSOLoginPage = () => {
  const ssoUrl = `${config.ssoFrontendLoginUrl}?redirectUrl=${window.location.href}`;
  window.location.replace(ssoUrl);
};
