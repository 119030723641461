import { config } from '@config';
import packageJson from '../../package.json';
import {
  AuthApi,
  Configuration as IamConfiguration
} from '@xq/iam-frontend-client';
import { ConfigurationParameters } from '@xq/iam-frontend-client';
import {
  AllApi,
  Configuration as UserProfileConfiguration
} from '@xq/user-profile-gateway-frontend-client';

export interface AppVersion {
  version: string;
  name: string;
}

export const appVersion: AppVersion = {
  version: packageJson.version,
  name: packageJson.name
};

const getConfiguration = (basePath: string): ConfigurationParameters => {
  return {
    basePath: basePath,
    credentials: 'include',
    headers: {
      'xq-app-version': appVersion.version,
      'xq-app-name': appVersion.name
    }
  };
};

export const iamGateway = new AuthApi(
  new IamConfiguration(getConfiguration(config.apiUrl))
);

export const userProfileGateway = new AllApi(
  new UserProfileConfiguration(
    getConfiguration(`${config.apiUrl}/user-profile`)
  )
);
